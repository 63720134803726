/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import feather from 'feather-icons';

/**
 * Primary UI component for user interaction
 */
const Button = ({
  variant,
  disabled,
  size,
  type,
  icon,
  position,
  label,
  onClick,
  children,
  className
}) => {
  const iconSize = (sizeSlug) => {
    if (sizeSlug === 'sm') {
      return { width: 16, height: 16 };
    }
    if (sizeSlug === 'lg') {
      return { width: 24, height: 24 };
    }
    return { width: 16, height: 16 };
  };
  return (
    <button
      data-testid="button"
      type="button"
      disabled={disabled ? 1 : 0}
      onClick={onClick}
      className={[
        'ragnarok',
        'button',
        `button-${size}`,
        `button-${type}`,
        `button-${variant}-medium`,
        `${icon ? 'icon' : ''}`,
        `${position !== 'none' && icon ? `icon-${position}` : ''}`,
        `${!label && icon ? 'no-label' : ''}`,
        `${icon && !(label || children) ? 'border-radius-circle' : 'border-radius-pill'}`,
        className
      ].join(' ')}
    >
      {icon && position === 'left' && (label || children) && (
        <i
          data-testid="icon"
          style={{ height: iconSize(size).height }}
          dangerouslySetInnerHTML={{
            __html: feather.icons[icon].toSvg({
              'stroke-width': 1.5,
              width: iconSize(size).width,
              height: iconSize(size).height
            })
          }}
        />
      )}
      {(children || label) && (
        <span className="label">{children || label}</span>
      )}
      {icon && position === 'right' && (label || children) && (
        <i
          data-testid="icon"
          style={{ height: iconSize(size).height }}
          dangerouslySetInnerHTML={{
            __html: feather.icons[icon].toSvg({
              'stroke-width': 1.5,
              width: iconSize(size).width,
              height: iconSize(size).height
            })
          }}
        />
      )}
      {icon && !(label || children) && (
        <i
          data-testid="icon"
          style={{ height: iconSize(size).height }}
          dangerouslySetInnerHTML={{
            __html: feather.icons[icon].toSvg({
              'stroke-width': 1.5,
              width: iconSize(size).width,
              height: iconSize(size).height
            })
          }}
        />
      )}
    </button>
  );
};

Button.propTypes = {
  /**
   * What variant color to show?
   */
  variant: PropTypes.oneOf([
    'primary',
  ]),
  /**
   * Should it be disabled?
   */
  disabled: PropTypes.bool,
  /**
   * How big should the button be?
   */
  size: PropTypes.oneOf(['sm', 'lg']),
  /**
   * What type is the best?
   */
  type: PropTypes.oneOf([
    'filled',
    'outlined',
    'ghost',
  ]),
  /**
   * Should an Icon be shown? Which one?
   */
  icon: PropTypes.oneOf([
    false,
    'activity',
    'airplay',
    'alert-circle',
    'alert-octagon',
    'alert-triangle',
    'align-center',
    'align-justify',
    'align-left',
    'align-right',
    'anchor',
    'aperture',
    'archive',
    'arrow-down',
    'arrow-down-circle',
    'arrow-down-left',
    'arrow-down-right',
    'arrow-left',
    'arrow-left-circle',
    'arrow-right',
    'arrow-right-circle',
    'arrow-up',
    'arrow-up-circle',
    'arrow-up-left',
    'arrow-up-right',
    'at-sign',
    'award',
    'bar-chart',
    'bar-chart-2',
    'battery',
    'battery-charging',
    'bell',
    'bell-off',
    'bluetooth',
    'bold',
    'book',
    'bookmark',
    'book-open',
    'box',
    'briefcase',
    'calendar',
    'camera',
    'camera-off',
    'cast',
    'check',
    'check-circle',
    'check-square',
    'chevron-down',
    'chevron-left',
    'chevron-right',
    'chevrons-down',
    'chevrons-left',
    'chevrons-right',
    'chevrons-up',
    'chevron-up',
    'chrome',
    'circle',
    'clipboard',
    'clock',
    'cloud',
    'cloud-drizzle',
    'cloud-lightning',
    'cloud-off',
    'cloud-rain',
    'cloud-snow',
    'code',
    'codepen',
    'codesandbox',
    'coffee',
    'columns',
    'command',
    'compass',
    'copy',
    'corner-down-left',
    'corner-down-right',
    'corner-left-down',
    'corner-left-up',
    'corner-right-down',
    'corner-right-up',
    'corner-up-left',
    'corner-up-right',
    'cpu',
    'credit-card',
    'crop',
    'crosshair',
    'database',
    'delete',
    'disc',
    'divide',
    'divide-circle',
    'divide-square',
    'dollar-sign',
    'download',
    'download-cloud',
    'dribbble',
    'droplet',
    'edit',
    'edit-2',
    'edit-3',
    'external-link',
    'eye',
    'eye-off',
    'facebook',
    'fast-forward',
    'feather',
    'figma',
    'file',
    'file-minus',
    'file-plus',
    'file-text',
    'film',
    'filter',
    'flag',
    'folder',
    'folder-minus',
    'folder-plus',
    'framer',
    'frown',
    'gift',
    'git-branch',
    'git-commit',
    'github',
    'gitlab',
    'git-merge',
    'git-pull-request',
    'globe',
    'grid',
    'hard-drive',
    'hash',
    'headphones',
    'heart',
    'help-circle',
    'hexagon',
    'home',
    'image',
    'inbox',
    'info',
    'instagram',
    'italic',
    'key',
    'layers',
    'layout',
    'life-buoy',
    'link',
    'link-2',
    'linkedin',
    'list',
    'loader',
    'lock',
    'log-in',
    'log-out',
    'mail',
    'map',
    'map-pin',
    'maximize',
    'maximize-2',
    'meh',
    'menu',
    'message-circle',
    'message-square',
    'mic',
    'mic-off',
    'minimize',
    'minimize-2',
    'minus',
    'minus-circle',
    'minus-square',
    'monitor',
    'moon',
    'more-horizontal',
    'more-vertical',
    'mouse-pointer',
    'move',
    'music',
    'navigation',
    'navigation-2',
    'nomes.txt',
    'octagon',
    'package',
    'paperclip',
    'pause',
    'pause-circle',
    'pen-tool',
    'percent',
    'phone',
    'phone-call',
    'phone-forwarded',
    'phone-incoming',
    'phone-missed',
    'phone-off',
    'phone-outgoing',
    'pie-chart',
    'play',
    'play-circle',
    'plus',
    'plus-circle',
    'plus-square',
    'pocket',
    'power',
    'printer',
    'radio',
    'refresh-ccw',
    'refresh-cw',
    'repeat',
    'rewind',
    'rotate-ccw',
    'rotate-cw',
    'rss',
    'save',
    'scissors',
    'search',
    'send',
    'server',
    'settings',
    'share',
    'share-2',
    'shield',
    'shield-off',
    'shopping-bag',
    'shopping-cart',
    'shuffle',
    'sidebar',
    'skip-back',
    'skip-forward',
    'slack',
    'slash',
    'sliders',
    'smartphone',
    'smile',
    'speaker',
    'square',
    'star',
    'stop-circle',
    'sun',
    'sunrise',
    'sunset',
    'tablet',
    'tag',
    'target',
    'terminal',
    'thermometer',
    'thumbs-down',
    'thumbs-up',
    'toggle-left',
    'toggle-right',
    'tool',
    'trash',
    'trash-2',
    'trello',
    'trending-down',
    'trending-up',
    'triangle',
    'truck',
    'tv',
    'twitch',
    'twitter',
    'type',
    'umbrella',
    'underline',
    'unlock',
    'upload',
    'upload-cloud',
    'user',
    'user-check',
    'user-minus',
    'user-plus',
    'users',
    'user-x',
    'video',
    'video-off',
    'voicemail',
    'volume',
    'volume-1',
    'volume-2',
    'volume-x',
    'watch',
    'wifi',
    'wifi-off',
    'wind',
    'x',
    'x-circle',
    'x-octagon',
    'x-square',
    'youtube',
    'zap',
    'zap-off',
    'zoom-in',
    'zoom-out'
  ]),
  /**
   * Left or right of Label?
   */
  position: PropTypes.oneOf([
    'none',
    'left',
    'right'
  ]),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Button.defaultProps = {
  variant: 'primary',
  disabled: false,
  type: 'filled',
  size: 'sm',
  icon: false,
  position: 'none',
  onClick: undefined,
};

export {
  Button
};
